@media screen and (min-width: 1450px) {
    .page {
        flex-direction: row !important;
    }

    .content-box {
        width: 50% !important;
    }
}

